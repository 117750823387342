import React from 'react'

import {
  Wrap,
  IconTwitter,
  IconInstagram,
  IconFaceBook,
  IconYouTube,
  IconLinkedIn,
} from './styles'

const SocialIcons: React.FC = (): React.ReactElement => (
  <Wrap>
    <IconTwitter
      href='http://www.twitter.com/insurancelounge'
      target='_blank'
      rel='noopener noreferrer'
    />
    <IconYouTube
      href='https://youtube.com/@InsuranceLoungeOregon'
      target='_blank'
      rel='noopener noreferrer'
    />
    <IconFaceBook
      href='http://www.facebook.com/InsuranceLounge'
      target='_blank'
      rel='noopener noreferrer'
    />
    <IconLinkedIn
      href='https://www.linkedin.com/company/insurance-lounge'
      target='_blank'
      rel='noopener noreferrer'
    />
    <IconInstagram
      href='https://www.instagram.com/insurancelounge'
      target='_blank'
      rel='noopener noreferrer'
    />
  </Wrap>
)

export default SocialIcons
