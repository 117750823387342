import styled from 'styled-components'

import Facebook from 'assets/images/social/Facebook.svg'
import Youtube from 'assets/images/social/YouTube.svg'
import Twitter from 'assets/images/social/Twitter.svg'
import LinkedIn from 'assets/images/social/LinkedIn.svg'
import Instagram from 'assets/images/social/Instagram.svg'

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 25px 0;
`

export const SocialIcon = styled.a`
  display: inline-block;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  margin: 10px 10px 0;
`

export const IconFaceBook = styled(SocialIcon)`
  background-image: url(${Facebook});
`

export const IconInstagram = styled(SocialIcon)`
  background-image: url(${Instagram});
`

export const IconYouTube = styled(SocialIcon)`
  background-image: url(${Youtube});
`

export const IconLinkedIn = styled(SocialIcon)`
  background-image: url(${LinkedIn});
`

export const IconTwitter = styled(SocialIcon)`
  background-image: url(${Twitter});
`
