import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import SocialIcons from 'components/blocks/SocialIcons'

import {
  events,
  useGoogleTagManager,
} from 'hooks/useGoogleTagManager'

import packageJson from '../../../package.json'

import { Props } from './types'

import {
  FooterWrap,
  FooterNav,
  FooterNavSub,
  FooterNavLink,
  FooterNavLinkExternal,
  FooterNavLinkSub,
  FooterNavLinkSubSpan,
  FooterNavLinkGrey,
  FooterLegal,
  Version,
  Copyright,
} from './styles'

const Footer: React.FC<Props> = ({ location: { pathname } }): React.ReactElement | null => {
  const { pushDataLayerEvent } = useGoogleTagManager()

  useEffect(() => {
    const iframe: HTMLIFrameElement | undefined = document.querySelector('iframe#web-messenger-container') as HTMLIFrameElement || undefined
    if (iframe) {
      iframe.style.display = pathname === '/' ? 'none' : 'block'
    }
  })

  const currentYear = new Date().getFullYear()

  return (
    (pathname !== '/client-self-service' && (
      <FooterWrap>
        <FooterNav>
          <FooterNavLink to='/'>Home</FooterNavLink>
          <FooterNavLink
            onClick={() => pushDataLayerEvent({
              ...events.cta_get_quote,
              button_position: 'footer',
            })}
            to='/quote/new'
          >
            Quote &amp; Buy
          </FooterNavLink>
          <FooterNavLink to='/products'>Products</FooterNavLink>
          <FooterNavLink to='/locations/storefronts'>Storefronts</FooterNavLink>
          <FooterNavLink to='/about-us'>About Us</FooterNavLink>
          <FooterNavLink to='/franchising'>Franchising</FooterNavLink>
          <FooterNavLink to='/support'>Support</FooterNavLink>
          <FooterNavLink to='/contact'>Contact Us</FooterNavLink>
        </FooterNav>
        <FooterNavSub>
          <FooterNavLinkSub to='/locations/storefronts/beaverton'>Beaverton, OR</FooterNavLinkSub><FooterNavLinkSubSpan> | </FooterNavLinkSubSpan>
          <FooterNavLinkSub to='/locations/storefronts/eugene'>Eugene, OR</FooterNavLinkSub><FooterNavLinkSubSpan> | </FooterNavLinkSubSpan>
          <FooterNavLinkSub to='/locations/storefronts/grants-pass'>Grants Pass, OR</FooterNavLinkSub><FooterNavLinkSubSpan> | </FooterNavLinkSubSpan>
          <FooterNavLinkSub to='/locations/storefronts/las-vegas'>Las Vegas, NV</FooterNavLinkSub><FooterNavLinkSubSpan> | </FooterNavLinkSubSpan>
          <FooterNavLinkSub to='/locations/storefronts/medford'>Medford, OR</FooterNavLinkSub>
        </FooterNavSub>

        <SocialIcons />
        <FooterLegal>
          We do not offer every plan available in your area. Currently we represent 14 organizations which offer 73 products in your area. Please contact <FooterNavLinkExternal isExternal={true} to='http://medicare.gov/'>Medicare.gov</FooterNavLinkExternal>, 1-800-MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your options.
        </FooterLegal>
        <Copyright>
          © {currentYear} Insurance Lounge<sup>®</sup>. All rights reserved. 800-211-8660{' '}
          <FooterNavLinkGrey to='/privacy/'>Privacy Policy</FooterNavLinkGrey> &{' '}
          <FooterNavLinkGrey to='/terms/'>Terms of Use</FooterNavLinkGrey>.
          {(packageJson && packageJson.version && (
            <Version>{`v${packageJson.version}`}</Version>
          )) ||
          undefined}
        </Copyright>

      </FooterWrap>
    )) ||
    null // eslint-disable-line no-null/no-null
  )
}

export default withRouter(Footer)
